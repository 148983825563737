import { FloatingOverlay as FloatingOverlayBase, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { useRouting } from '@kaliber/routing'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { useElementSize } from '@kaliber/use-element-size'
import { animated, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import { useTranslate } from '/machinery/I18n'
import { useSearchContext } from '/machinery/SearchContext'
import { ensureBoolean } from '/machinery/ensure'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { routeMap } from '/routeMap'

import { Checkbox } from '/features/buildingBlocks/Checkbox'
import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCover } from '/features/buildingBlocks/Image'
import { SearchForm } from '/features/buildingBlocks/SearchForm'
import { TagButton } from '/features/buildingBlocks/TagButton'
import { LoaderCircleGray } from '/features/pageOnly/Loader'
import { Logo, LogoSpecials } from '/features/regionArticles/Logo'
import { DrawerArticleLinkSearch } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { useSearchArticles, useSearchArticlesCanonical, useSearchQueryParams, useTags } from '/features/search/useSearchArticles'

import mediaStyles from '/cssGlobal/media.css'
import styles from './SearchDrawer.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'
import markIcon from '/images/icons/mark.raw.svg'

export function SearchDrawer({ isOpen, onClose, coverImage, layoutClassName = undefined }) {
  const { refs, getFloatingProps } = useFloatingProps({ isOpen, onClose })

  return (
    <div aria-hidden={!isOpen} className={cx(styles.component, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <Drawer
          floatingProps={getFloatingProps()}
          layoutClassName={styles.drawerLayout}
          {...{ isOpen, refs, onClose, coverImage }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

export function SearchCanonical({ isOpen, onClose, coverImage = getCanonicalCoverImage(), layoutClassName = undefined }) {
  const { refs, getFloatingProps } = useFloatingProps({ isOpen, onClose })

  return (
    <div aria-hidden={!isOpen} className={cx(styles.componentCanonical, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <SearchDrawerCanonical
          floatingProps={getFloatingProps()}
          layoutClassName={styles.drawerLayout}
          {...{ isOpen, refs, onClose, coverImage }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

function Drawer({ isOpen, floatingProps, onClose, coverImage, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const [{ searchAllIssues }, setQueryString] = useSearchQueryParams()

  const { issue: currentIssue } = useSearchContext()
  const { tags, handleTagClick } = useTags()

  const { search, results, status } = useSearchArticles()
  const { withinRegion } = results
  const { isFiltering } = status

  return (
    <SearchDrawerWrapper {...{ isOpen, onClose, floatingProps, layoutClassName }}>
      <SearchHeader
        searchValue={search.value}
        onSearchChange={handleSearchChange}
        layoutClassName={styles.headerLayout}
        {...{ onClose, coverImage }}
      />

      {currentIssue && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            name='searchAllIssues'
            checked={searchAllIssues}
            dataX='seach-all-issue-checkbox'
            label={__`show-all-issues`}
            onChange={handleSearchAllIsssuesClick}
          />

          {Boolean(withinRegion.articles?.length) && (
            <p className={styles.resultCountElement}>
              {withinRegion.articles.length} {__(withinRegion.articles.length)`number-of-articles-found`}
            </p>
          )}
        </div>
      )}

      <Tags onTagClick={handleTagClick} {...{ isFiltering, tags }} />

      <Results {...{ search, results, status }} />
    </SearchDrawerWrapper>
  )

  function handleSearchAllIsssuesClick() {
    trackInteraction({
      action: 'clicked',
      type: 'click',
      title: 'search_all_editions'
    })

    setQueryString(x => ({ ...x, searchAllIssues: ensureBoolean(x.searchAllIssues) ? undefined : true }))
  }

  function handleSearchChange(value) {
    search.setValue(value)
  }
}

function SearchDrawerWrapper({ isOpen, onClose, floatingProps, children, layoutClassName }) {
  const { style, elementRef } = useDrawerStyle({ isOpen })

  const bind = useDrag(
    ({ swipe: [swipeX], movement: [movementX] }) => {
      if (swipeX === 1 || movementX > 150) onClose()
    }, { axis: 'x' }
  )

  return (
    <animated.div
      ref={elementRef}
      className={cx(styles.componentDrawerWrapper, layoutClassName)}
      {...{ style, ...floatingProps, ...bind() }}
    >
      {children}
    </animated.div>
  )
}

function SearchHeader({ searchValue, onSearchChange, onClose, coverImage = undefined, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <header className={cx(styles.componentHeader, layoutClassName)}>
      {coverImage && (
        <div className={styles.imageContainer}>
          <ImageCover
            image={coverImage}
            aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
            layoutClassName={styles.imageLayout}
          />
        </div>
      )}

      <div className={styles.headerWrapper}>
        <div className={styles.headerContentContainer}>
          <button onClick={onClose} data-x='click-to-close-search' className={styles.backButton}>
            <Icon icon={arrowRight} layoutClassName={styles.iconLayout} />
          </button>

          <ContextAwareLogo layoutClassName={styles.logoLayout} />

          <SearchForm value={searchValue} onChange={onSearchChange} layoutClassName={styles.formLayout} />
        </div>
      </div>
    </header>
  )
}

function ContextAwareLogo({ layoutClassName }) {
  const { matchRoutes } = useRouting()

  return matchRoutes(
    [routeMap.app, <Logo {...{ layoutClassName }} />],
    [routeMap.app.specials, <LogoSpecials {...{ layoutClassName }} />]
  )
}

function Results({ results, status, search }) {
  const { __ } = useTranslate()

  const { isFetching } = status
  const { withinRegion, notWithinRegion } = results
  const noArticlesFound = search.value.length && !withinRegion.articles?.length && !notWithinRegion.articles?.length && !isFetching

  return (
    <>
      <ResultsStatus {...{ isFetching, noArticlesFound }} />
      <ul className={styles.resultsContainer}>
        {withinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
        {Boolean(notWithinRegion.total) && <h3 className={styles.differentRegionsHeading}>{__`from-different-regions`}</h3>}
        {notWithinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
      </ul>
    </>
  )
}

function ResultsStatus({ isFetching, noArticlesFound }) {
  const { __ } = useTranslate()

  return (
    <>
      {noArticlesFound && (
        <div className={styles.drawerContainer}>
          <SearchHeading text={`${__`no-search-results`}:`} />
        </div>
      )}

      {isFetching && (
        <div className={styles.drawerContainer}>
          <LoaderCircleGray layoutClassName={styles.loaderLayout} />
        </div>
      )}
    </>
  )
}

function SearchDrawerCanonical({ isOpen, floatingProps, onClose, coverImage = undefined, layoutClassName = undefined }) {
  const [{ searchAllIssues }, setQueryString] = useSearchQueryParams()
  const { tags, handleTagClick } = useTags()
  const { __ } = useTranslate()

  const { search, results, status } = useSearchArticlesCanonical({ size: 999 })
  const { articles } = results
  const { isFiltering } = status
  const { issue: currentIssue } = useSearchContext()

  return (
    <SearchDrawerWrapper {...{ isOpen, onClose, floatingProps, layoutClassName }}>
      <SearchHeader searchValue={search.value} onSearchChange={handleSearchChange} layoutClassName={styles.headerLayout} {...{ onClose, coverImage }} />

      {currentIssue && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            name='searchAllIssues'
            checked={searchAllIssues}
            dataX='seach-all-issue-checkbox'
            label={__`show-all-issues`}
            onChange={handleSearchAllIsssuesClick}
          />

          {Boolean(articles?.length) && (
            <p className={styles.resultCountElement}>{articles.length} {__(articles.length)`number-of-articles-found`}</p>
          )}
        </div>
      )}

      <Tags onTagClick={handleTagClick} {...{ isFiltering, tags }} />

      <ResultsCanonical {...{ search, results, status }} />
    </SearchDrawerWrapper>
  )

  function handleSearchAllIsssuesClick() {
    trackInteraction({
      action: 'clicked',
      type: 'click',
      title: 'search_all_editions'
    })
    setQueryString(x => ({ ...x, searchAllIssues: ensureBoolean(x.searchAllIssues) ? undefined : true }))
  }

  function handleSearchChange(value) {
    search.setValue(value)
  }
}

function Tags({ isFiltering, tags, onTagClick }) {
  return (
    <>
      {tags.length > 0 && <SelectedSuggestion activeTags={tags.filter(x => x.isActive)} {...{ onTagClick }} />}
      {!isFiltering && <SearchSuggestions {...{ tags, onTagClick }} />}
    </>
  )
}

function ResultsCanonical({ results, status, search }) {
  const { isFetching } = status
  const { articles } = results
  const noArticlesFound = search.value.length && !articles?.length && !isFetching

  return (
    <>
      <ResultsStatus {...{ isFetching, noArticlesFound }} />

      <ul className={styles.resultsContainer}>
        {articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
      </ul>
    </>
  )
}

function SearchSuggestions({ tags, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSuggestions, layoutClassName)}>
      <SearchHeading text={`${__`suggestions`}:`} />

      <ul className={styles.suggestionsList}>
        {tags.slice(0, 5).map((item, i) => (
          <Suggestion key={i} {...{ item }} />
        ))}
      </ul>
    </div>
  )
}

function SearchHeading({ text }) {
  return <h3 className={styles.componentHeading}>{text}</h3>
}

function Suggestion({ item }) {
  const [, setQueryString] = useQueryString()
  const { buttonId } = item

  return (
    <TagButton onClick={handleClick} label={`${item.label} (${item.count})`} dataX='click-to-select-suggested-search-tag' {...{ buttonId }} />
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: [item.value] }))
  }
}

function SelectedSuggestion({ activeTags }) {
  const [, setQueryString] = useQueryString()

  return (
    <ul className={styles.componentSelectedSuggestion}>
      {activeTags.map((tag, i) => (
        <TagButton key={i} buttonId={tag.buttonId} onClick={handleClick} label={tag.label} icon={markIcon} dataX='click-to-remove-suggested-search-tag' layoutClassName={styles.tagLayout} />
      ))}
    </ul>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: undefined }))
  }
}

function useDrawerStyle({ isOpen }) {
  const { size: { width }, ref: elementRef } = useElementSize()

  const [style, api] = useSpring(() => ({
    right: '-100%',
  }))

  React.useEffect(
    () => {
      api.start({
        right: isOpen ? '0px' : `-${width}px`
      })
    },
    [isOpen, api, width]
  )

  return { style, elementRef }
}

function useFloatingProps({ isOpen, onClose }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) onClose()
    }
  })

  const dismiss = useDismiss(context, { capture: false })
  const { getFloatingProps } = useInteractions([dismiss])

  return { refs, getFloatingProps }
}


function getCanonicalCoverImage() {
  return {
    asset: { _ref: 'image-9c280c718d4d0c8eeb68cf3711ba53bd-1667x2500-webp' },
    metadata: {
      lqip: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAMAAAAbzM5ZAAAABlBMVEUuIxtAMiehWDQZAAAACXBIWXMAAAPoAAAD6AG1e1JrAAAAQUlEQVR4nN2MiQ0AIAgDuf2XNryCTqA1KfZoEPlf3JkDaDbMKNCRff1J0UrMJW4kqjVhbWaxI1PMuLQ36tkZegUure4BGlW2hmkAAAAASUVORK5CYII='
    },
    hotspot: { x: 0.468160336186731, y: 0.3844797178130511, width: 1, height: 1 },
    crop: { right: 0, top: 0, left: 0, bottom: 0 }
  }
}
