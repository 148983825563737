import { ImageCover } from '/features/buildingBlocks/Image'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'

import styles from './IssueCard.css'

import chevron from '/images/icons/chevron-right.raw.svg'

export function IssueCard({ logo, title, image, href, layoutClassName = undefined }) {
  return (
    <IssueCardBase dataX='link-to-edition' className={layoutClassName} {...{ title, href }}>
      {image?.asset && <ImageCover aspectRatio={1 / 1} {...{ image }} />}
      <img src={logo} alt="logo" width='72px' className={styles.logo} />
    </IssueCardBase>
  )
}

export function IssueCardArchief({ title, href, layoutClassName = undefined  }) {
  return (
    <IssueCardBase dataX='link-to-archive' className={layoutClassName} {...{ title, href }}>
      <img src='/images/archief.png' alt='collection' />
    </IssueCardBase>
  )
}

function IssueCardBase({ title, href, dataX, children, className = undefined }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.imageAndLogo}>
        {children}
      </div>

      {href && (
        <ButtonLinkPrimary
          icon={chevron}
          label={title}
          layoutClassName={styles.buttonLayout}
          {...{ href, dataX }}
        />
      )}
    </div>
  )
}
