import { useKeenSlider } from 'keen-slider/react'

import { useTranslate } from '/machinery/I18n'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { stopPropagationEventHandlers } from '/machinery/stopPropagationEventHandlers'
import { IssueCard, IssueCardArchief } from '/features/buildingBlocks/IssueCard'

import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './IssueSlider.css'

import logoDefault from '/images/branding/rabo-en-co-logo.svg'
import logoSpecials from '/images/branding/rabo-en-co-specials-logo.svg'

export function IssueSliderSpecials({
  issues,
  title = undefined,
  origin = undefined,
  slidesPerView = undefined,
  layoutClassName = undefined
}) {
  return (
    <IssueSlider
      logo={logoSpecials}
      {...{ issues, title, origin, slidesPerView, layoutClassName }}
    />
  )
}

function IssueSlider({
  issues,
  logo = logoDefault,
  title = undefined,
  origin = undefined,
  slidesPerView = undefined,
  layoutClassName = undefined
}) {
  return (
    <IssueSliderBase className={layoutClassName} {...{ origin, slidesPerView, title }}>
      {issues.map(({ title, image, href }, i) =>
        <IssueCard key={i} {...{ logo, title, image, href }} />
      )}
    </IssueSliderBase>
  )
}

export function IssueSliderWithArchive({
  issues,
  title = undefined,
  origin = undefined,
  slidesPerView = undefined,
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()

  return (
    <IssueSliderBase className={layoutClassName} {...{ origin, slidesPerView, title }}>
      {issues.map(({ title, image, href }, i) =>
        <IssueCard key={i} logo={logoDefault} {...{ title, image, href }} />
      )}

      <IssueCardArchief href='https://raboenco.pdf.altavia-unite.nl' title={__`archive`} />
    </IssueSliderBase>
  )
}

function IssueSliderBase({
  children,
  title = undefined,
  origin = undefined,
  slidesPerView = undefined,
  className = undefined
}) {
  const [sliderRef] = useKeenSliderRefs({
    slides: React.Children.count(children),
    selector: `.${styles.slide}`,
    origin
  })

  return (
    <div className={cx(styles.componentBase, className)}>
      {title && <HeadingSm h={2}>{title}</HeadingSm>}

      <div
        ref={sliderRef}
        className={cx(styles.slider, 'keen-slider')}
        style={slidesPerView ? { '--slides-per-view': slidesPerView } : {}}
        {...stopPropagationEventHandlers()}
      >
        {React.Children.map(children, (child, i) => (
          <div key={i} className={cx(styles.slide, styles._rootSlide)}>
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

function useKeenSliderRefs({ origin, selector, slides }) {
  return useTrackedKeenSlider({
    mode: 'free-snap',
    range: { align: true },
    selector,
    slides: {
      number: slides.length,
      origin: origin || 0,
      perView: 'auto',
    }
  })
}

function useTrackedKeenSlider(config) {
  return useKeenSlider(config, [keenSliderTrackingPlugin])
}

function keenSliderTrackingPlugin() {
  return slider => {
    slider.on('slideChanged', () => trackDragInteraction('slide_changed'))
    slider.on('dragStarted', () => trackDragInteraction('drag_started'))
    slider.on('dragEnded', () => trackDragInteraction('drag_ended'))
  }

  function trackDragInteraction(action) {
    trackInteraction({
      action,
      title: 'interaction',
      type: 'drag'
    })
  }
}
