import { useGesture } from '@use-gesture/react'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useDrawerGestures({ dragGesturesEnabled, dismissGestureInstruction = undefined, instructionsDismissed = undefined }) {
  const [tocIsOpen, setTocIsOpen] = React.useState(false)
  const [searchIsOpen, setSearchIsOpen] = React.useState(false)

  const tocDrawer =  {
    isOpen: tocIsOpen,
    handleOpen: () => {
      if (tocIsOpen) return

      setSearchIsOpen(false)
      setTocIsOpen(true)

      instructionsDismissed !== true && dismissGestureInstruction && dismissGestureInstruction()
    },
    handleClose: () => {
      if (!tocIsOpen) return

      setTocIsOpen(false)
    }
  }

  const searchDrawer = {
    isOpen: searchIsOpen,
    handleOpen: () => {
      if (searchIsOpen) return

      setSearchIsOpen(true)
      setTocIsOpen(false)
    },
    handleClose: () => {
      if (!searchIsOpen) return

      setSearchIsOpen(false)
    },
  }

  const bind = useGestures({ dragGesturesEnabled, tocDrawer, searchDrawer })

  return { bind, searchDrawer, tocDrawer }
}

function useGestures({ dragGesturesEnabled, tocDrawer, searchDrawer }) {
  const [drawerIsActive, setDrawerIsActive] = React.useState(false)

  const bind = useGesture({
    onDrag: ({ swipe: [swipeX], movement: [movementX], down }) => {
      if (!dragGesturesEnabled) return

      const selectedText = window.getSelection().toString()
      if (selectedText.length > 0) return

      if (!down) setDrawerIsActive(tocDrawer.isOpen || searchDrawer.isOpen)

      if (isSwipeRight({ swipeX, movementX })) {
        if (drawerIsActive) return handleCloseSearch()
        else handleOpenToc()
      } else if (isSwipeLeft({ swipeX, movementX })) {
        if (drawerIsActive) return handleCloseToc()
        else handleOpenSearch()
      }
    },
    onDragEnd: ({ swipe: [swipeX], movement: [movementX] }) => {
      if (isSwipeRight({ swipeX, movementX })) {
        if (!drawerIsActive) trackInteraction({ title: 'open toc', action: 'open', type: 'drag' })
      } else if (isSwipeLeft({ swipeX, movementX })) {
        if (!drawerIsActive) trackInteraction({ title: 'open search', action: 'open', type: 'drag' })
      }
    }
  }, { drag: { axis: 'x' } })

  return bind

  function handleOpenToc() {
    if (tocDrawer.isOpen) return

    tocDrawer.handleOpen()
  }

  function handleCloseToc() {
    tocDrawer.handleClose()
    setDrawerIsActive(false)
  }

  function handleOpenSearch() {
    if (searchDrawer.isOpen) return

    searchDrawer.handleOpen()
  }

  function handleCloseSearch() {
    searchDrawer.handleClose()
    setDrawerIsActive(false)
  }
}

function isSwipeRight({ swipeX, movementX }) {
  return (swipeX === 1 || movementX > 150)
}

function isSwipeLeft({ swipeX, movementX }) {
  return (swipeX === -2 || movementX < -175)
}
